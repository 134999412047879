import React from "react"
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

import Dashboard from './components/dashboard/Dashboard.js';
import Login from './components/login/Login.js';
import Usuarios from './components/usuarios/Usuarios.js';
import Equipamentos_servico from './components/modulo/Modulo.js';





function App() {
    return (
        <Router>

            <Routes>
                
                <Route exact path="/"   element={<Dashboard />} />
                <Route path="/login"   element={<Login />}  />
                <Route path="/cadastro-usuarios"   element={<Usuarios />}  />
                <Route path="/cadastro-url"   element={<Equipamentos_servico />}  />
            </Routes>
        </Router>

    )
}
export default App;

