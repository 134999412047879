import React, { useState, useEffect } from 'react';

import styled from "styled-components";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";





const FormContainer = styled.form`
  width: 100%;
  max-width: 440px;
  background-color: rgba(255, 255, 255, 0.8);
  justify-content: center; 
  display: flex; 
  padding: 5px 10px;
  box-shadow: 0px 0px 2px #ccc;
  border-radius: 1px;
  align-items: center;
  gap: 5px;
  flex-wrap: wrap;
  

  @media (max-width: 1360px) {
    max-width: 440px;
    justify-content: space-between; 
  }
  @media (max-width: 1024px) {
    width: 440px;
    justify-content: space-between; 
  }
  @media (max-width: 640px) {
    width: 380px;
    justify-content: space-between; 
  }
  @media (max-width: 480px) {

    width: 340px;
    justify-content: space-between; 
  }
  @media (max-width: 376px) {
    width: 320px;
  }
`;


const Container = styled.div`
  width: 500px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: flex-start; 
  text-align: start;
  gap: 10px;
  padding: 0px 10px; 
  height: auto;

  @media (max-width: 1360px) {
    width: 500px;
  }
  @media (max-width: 640px) {
    width: 440px;
  }
  @media (max-width: 480px) {
    width: 380px;
  }
  @media (max-width: 376px) {
    width: 340px;
  }
`;


const Title2 = styled.h2`
  color: #4f4f4f;
  padding-top: 2vw;
  display: flex;
  justify-content: center; 
  align-items: center;
  flex-direction: column;
  h4 {
    font-family: Tahoma;
  }

  @media (max-width: 1360px) {
    font-size: 22px;
    padding-left: 15%;
  }
 
  @media (max-width: 640px) {
    font-size: 22px;
    padding-left: 10%;
  }
  @media (max-width: 480px) {
    font-size: 22px;
    padding-top: 10%;
  }
  @media (max-width: 376px) {
    font-size: 20px;
    display: flex;
    padding-left: 10%;

  }

`;


const Input = styled.input`
  width: 400px;
  border: 1px solid #bbb;
  border-radius: 1px;
  height: 37px;
  padding: 0px 10px;
  @media (max-width: 1024px) {
    width: 400px;
    height: 37px;
  }
  @media (max-width: 840px) {
    width: 400px;
    height: 37px;
  }
  @media (max-width: 640px) {
    width: 330px;
    height: 37px;
  }
  @media (max-width: 480px) {
    width: 290px;
  }
  @media (max-width: 376px) {
    width: 270px;
  }
`;

const Label = styled.label`
  padding-top: 25px;
  font-weight: bold;
  font-size: 17px;
  text-align: left
  color: #808080;
  p{
    color: #4f4f4f;
  }
  
  @media (max-width: 500px) {
  font-size: 20px;
  }
`;


const ContainerButton = styled.div`
  display: flex;
  justify-content: center; 
  width: 400px;

  @media (max-width: 640px) {
    .alterar {
      display: none;
    }
  }

  @media (max-width: 480px) {
    align-items: center;
    .alterar {
      display: none;
    }
  }
  @media (max-width: 376px) {
    align-items: center;
    .alterar {
      display: none;
    }
  }
`;


const Button = styled.button`
  padding: 10px;
  margin: 20px;
  cursor: pointer;
  border-radius: 3px;
  border: none;
  background-color: #7A7A7A;
  color: white;
  width: 120px;
  height: 40px;
  font-weight: bold;

  transition: transform 0.3s;
  &:hover {
    transform: scale(1.1);
    background-color: #000000;
  }
  
`;

const Button2 = styled.button`
  padding: 10px;
  margin: 20px;
  cursor: pointer;
  border-radius: 3px;
  border: none;
  background-color: #7A7A7A;
  color: white;
  width: 150px;
  height: 40px;
  font-weight: bold;

  transition: transform 0.3s;
  &:hover {
    transform: scale(1.1);
    background-color: #000000;
  }
  
`;






function Login({ urlApi, modalNovaSenha }) {
  const [login, setLogin] = useState('');
  const [senha, setSenha] = useState('');
  const [errorMessage, setErrorMessage] = useState('');



  // Função de login
  const handleLogin = async () => {
    if (!login || !senha) {
      setErrorMessage('Por favor, preencha todos os campos.');
      return;
    }

    try {

      const response = await fetch(`${urlApi}/login/`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          login,
          senha,
        }),
      });



      const data = await response.json();
      const tokenUsuario = data.token;
      const id = data.id_usuario;
      const usuario = data.login;


      if (response.ok) {
        localStorage.setItem('token', tokenUsuario);
        localStorage.setItem('id', id);
        localStorage.setItem('usuario', usuario);



        toast.success(data.mensagem);

        // Redefinir o formulário
        const form = document.querySelector('form');
        if (form) {
          form.reset();
        }

        // Salvar log de sucesso no banco de dados
        await saveLoginLog(login, 'Login realizado com sucesso!');

        setTimeout(function () {
          window.location.href = "/";
        }, 2000);

      } else {
        toast.error(data.error || 'Erro desconhecido no login.');

        // Salvar log de falha no banco de dados
        await saveLoginLog(login, `Falha no login: ${data.error || 'Erro desconhecido'}`);
      }
    } catch (error) {
      alert('Erro de rede ou erro desconhecido.');

      // Salvar log de falha de rede no banco de dados
      await saveLoginLog(login, 'Erro de rede ou erro desconhecido');
    }
  };


  // Passa o logs para serem salvos no banco
  const saveLoginLog = async (login, descricao) => {
    try {
      const logData = {
        login,
        descricao,
      };

      const response = await fetch(`${urlApi}/log`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
        body: JSON.stringify(logData),
      });

      if (!response.ok) {
        throw new Error('Failed to save log');
      }
    } catch (error) {
      console.error('Erro ao salvar log:', error);
    }
  };








  // Chamar a função com enter
  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault(); // Previne o comportamento padrão do Enter (submeter o formulário)
      handleLogin();
    }
  };



  const novaSenha = () => {
    modalNovaSenha();
  };


  return (
    <FormContainer onKeyDown={handleKeyDown}>
      <Title2>
        <h4>Portal de Indicadores</h4>

      </Title2>
      <Container>
        <Label>Usuário:</Label>
        <Input
          type="text"
          placeholder="Digite aqui..."
          value={login}
          onChange={(e) => setLogin(e.target.value)}
        />
        <Label>Senha:</Label>
        <Input
          type="password"
          placeholder="Digite aqui..."
          value={senha}
          onChange={(e) => setSenha(e.target.value)}
        />
        <ContainerButton>
          <Button type="button" onClick={handleLogin}>
            Entrar
          </Button>

          <Button2 type="button" onClick={novaSenha}>
            Esqueci minha senha
          </Button2>
        </ContainerButton>
      </Container>
    </FormContainer>
  );
}

export default Login;

