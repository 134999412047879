import axios from "axios";
import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { toast } from "react-toastify";
import { ImCheckmark, ImLoop2 } from "react-icons/im";
import Swal from "sweetalert2";
import { FaPlus } from "react-icons/fa6";

const FormContainer = styled.form`
  width: 100%;
  background-color: #fff;
  padding: 20px 20px;
  box-shadow: 0px 0px 2px #ccc;
  border-radius: 0.5px;
  margin: 0 auto; 
  flex-direction: row;

`;

const Div = styled.td`
  display: flex;
  flex-direction: row;
  justify-content: center;
`;

const InputArea = styled.div`
  width: auto;
  display: flex;
  flex-direction: column;
  padding: 10px;

  select {
    width: 250px;
    padding: 0 10px;
    border: 1px solid #bbb;
    border-radius: 1px;
    height: 37px;
  }



`;
const InputArea2 = styled.div`
  width: auto;
  display: flex;
  flex-direction: column;
  padding: 10px;
  margin-left: 30px;
  select {
    width: 250px;
    padding: 0 10px;
    border: 1px solid #bbb;
    border-radius: 1px;
    height: 37px;
  }


`;


const Input = styled.input`
  width: 230px;
  padding: 0 10px;
  border: 1px solid #bbb;
  border-radius: 1px;
  height: 37px;

  
`;

const Input2 = styled.input`
  width: 400px;
  padding: 0 5px;
  border: 1px solid #bbb;
  border-radius: 1px;
  height: 37px;

  
`;



const Label = styled.label`
  color: #4f4f4f;
  font-weight: bold;
  font-size: 17px;  
`;

const ButtonContainer = styled.div`
  width: 200px;
  display: flex;
  flex-direction: column;
  justify-content: center; 
  align-items: center;
  margin: 1px 1px 1px 1px;
 
`;

const ButtonLimpar = styled.button`
  padding-bottom: 10px;
  cursor: pointer;
  border: none;
  background-color:   #fff;
  font-weight: bold;
  font-size: 17px;
  margin: 8px;
  transition: transform 0.1s;
  &:hover {
    transform: scale(1.1);
  }
  
`;

const Button = styled.button`
  cursor: pointer;
  border: none;
  background-color:   #fff;
  font-weight: bold;
  font-size: 17px;
  margin: 8px;
  transition: transform 0.1s;
  &:hover {
    transform: scale(1.1);
  }
  
`;

const Div2 = styled.td`
  display: flex;
  flex-direction:column;
 
`;

const Div3 = styled.td`
  display: flex;
  align-items: center;
  flex-direction: rows;
 
`;





const Form = ({ onEdit, urlApi, fetchModulo, modulo, modalNovoModulo, fetchPagina, fetchAcesso }) => {

  const ref = useRef();

  // tokens
  const token = localStorage.getItem('token');
  const usuario = localStorage.getItem('usuario');


  //onEdit que vem do Grid para ser editado
  useEffect(() => {
    if (onEdit) {

      const user = ref.current;

      user.id_modulo.value = onEdit.id_modulo;
      user.desc_pagina.value = onEdit.desc_pagina;
      user.url.value = onEdit.url;
    };
  }, [onEdit]);



  //popUp que confirma a operação
  const showPopUp = () => {
    return new Promise((resolve) => {
      Swal.fire({
        title: "Deseja confirmar a ação?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Confirmar",
        cancelButtonText: "Cancelar",
        customClass: {
          container: 'custom-swal-container',
        },
      }).then((result) => {
        resolve(result.value); // Resolve a Promise com o valor result.value
      });
    });
  };


  const saveLog = async (login, descricao) => {
    try {
      const logData = {
        login,
        descricao,
      };

      const response = await fetch(`${urlApi}/log`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
        body: JSON.stringify(logData),
      });

      if (!response.ok) {
        throw new Error('Failed to save log');
      }
    } catch (error) {
      console.error('Erro ao salvar log:', error);
    }
  };




  const handleSubmit = async (e) => {
    e.preventDefault();

    const user = ref.current;

    const id_moduloValue = user.id_modulo.value;
    const desc_paginaValue = user.desc_pagina.value;
    const urlValue = user.url.value;


    if (!id_moduloValue || !desc_paginaValue || !urlValue) {
      return toast.warn("Preencha todos os campos!");
    }

    const userConfirmed = await showPopUp();
    if (!userConfirmed) {
      return;
    }

    try {
      if (onEdit) {

        await axios
          .put(`${urlApi}/pagina/${onEdit.id_pagina}`, {
            id_modulo: id_moduloValue,
            desc_pagina: desc_paginaValue,
            url: urlValue,
          }, { headers: { Authorization: `Bearer ${token}` } })
          .then(async ({ data }) => {
            fetchPagina();
            limpaForm(user);
            toast.success(data);

            await saveLog(usuario, `Editou a tela ${desc_paginaValue}`);
          })
          .catch(async (error) => {
            if (error.response) {
              const errorMessage = error.response.data.error || 'Erro desconhecido';
              console.log("Error:", errorMessage);
              toast.error("Erro ao editar: " + errorMessage);
              await saveLog(usuario, `Erro ao editar a descrição ${desc_paginaValue} pelo usuário ${usuario}: ${errorMessage}`);
            } else {
              console.error('Erro inesperado:', error);
              toast.error('Erro inesperado.');
              await saveLog(usuario, `Erro ao editar a descrição ${desc_paginaValue} pelo usuário ${usuario}`);
            }
          });
          const response = await axios.put(
            `${urlApi}/alterar-acesso/${onEdit.id_modulo}/${onEdit.id_pagina}`,
            {
              new_id_modulo: id_moduloValue,
              id_modulo: onEdit.id_modulo, 
              id_pagina: onEdit.id_pagina
            },
            {
              headers: {
                Authorization: `Bearer ${token}`
              }
            }
          );
      } else {

        await axios
          .post(`${urlApi}/pagina`, {
            id_modulo: id_moduloValue,
            desc_pagina: desc_paginaValue,
            url: urlValue,
          }, { headers: { Authorization: `Bearer ${token}` } })
          .then(async ({ data }) => {
            fetchPagina();
            limpaForm(user);
            toast.success(data);

            await saveLog(usuario, `Cadastrou a tela ${desc_paginaValue}`);
          })
          .catch(async (error) => {
            if (error.response) {
              const errorMessage = error.response.data.error || 'Erro desconhecido';
              console.log("Error:", errorMessage);
              toast.error("Erro ao adicionar: " + errorMessage);
              await saveLog(usuario, `Erro ao adicionar a descrição ${desc_paginaValue} pelo usuário ${usuario}: ${errorMessage}`);
            } else {
              console.error('Erro inesperado:', error);
              toast.error('Erro inesperado.');
              await saveLog(usuario, `Erro inesperado ao adicionar a descrição ${desc_paginaValue} pelo usuário ${usuario}`);
            }
          });
      }
    } catch (error) {
      console.error('Erro inesperado:', error);
      toast.error('Erro inesperado.');
      await saveLog(usuario, `Erro inesperado ao processar a descrição ${desc_paginaValue} pelo usuário ${usuario}`);
    }


  };


  const limpaForm = (user) => {
    user.id_modulo.value = "";
    user.desc_pagina.value = "";
    user.url.value = "";
  };

  const novoModulo = () => {
    modalNovoModulo();
  };



  return (
    <>
      <FormContainer ref={ref} onSubmit={handleSubmit}>
        <Div>
          <Div3>
            <InputArea>
              <Label>Modulo:</Label>
              <Div3>
                <select
                  name="id_modulo"

                >
                  <option value="">Escolha uma opção</option>
                  {modulo.map((mod) => (
                    <option key={mod.id_modulo} value={mod.id_modulo}>
                      {mod.desc_modulo}
                    </option>
                  ))}
                </select>
                <Button title="Criar novo modulo" type="button" onClick={novoModulo}>
                  <FaPlus color={"gold"} size={25} />
                </Button>
              </Div3>

            </InputArea>
            <InputArea2>
              <Label title="Crie um nome para o link,
              esse nome, será o nome que irá aparecer no painel">Descrição da tela:</Label>
              <Div3>
                <Input name="desc_pagina" type="text" ></Input>
              </Div3>
            </InputArea2>

            <InputArea2>
              <Label> Link do Power BI:</Label>
              <Div3>
                <Input2 name="url" type="text" ></Input2>
              </Div3>
            </InputArea2>


          </Div3>



          <Div2>
            <ButtonContainer>
              <ButtonLimpar title="Novo" type="button" onClick={() => window.location.reload()}>
                <ImLoop2 color={"gold"} /> Atualizar
              </ButtonLimpar>
              <Button title="Salvar" type="submit">
                <ImCheckmark color={"green"} /> Salvar
              </Button>
            </ButtonContainer>
          </Div2>

        </Div>
      </FormContainer>
    </>
  );
};

export default Form;


