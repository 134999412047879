import axios from "axios";
import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { toast } from "react-toastify";
import { ImCheckmark, ImLoop2 } from "react-icons/im";
import Swal from "sweetalert2";

const FormContainer = styled.form`
  width: 100%;
  background-color: #fff;
  padding: 20px 20px;
  box-shadow: 0px 0px 2px #ccc;
  border-radius: 0.5px;
  margin: 0 auto; 
  flex-direction: row;

`;

const Div = styled.td`
  display: flex;
  flex-direction: row;
  justify-content: center;
`;

const InputArea = styled.div`
  width: auto;
  display: flex;
  flex-direction: column;
  padding: 10px;

  select {
    width: 250px;
    padding: 0 10px;
    border: 1px solid #bbb;
    border-radius: 1px;
    height: 37px;
  }



`;

const Input = styled.input`
  width: 200px;
  padding: 0 10px;
  border: 1px solid #bbb;
  border-radius: 1px;
  height: 37px;

  
`;



const Label = styled.label`
  color: #4f4f4f;
  font-weight: bold;
  font-size: 17px;  
`;

const ButtonContainer = styled.div`
  width: 200px;
  display: flex;
  flex-direction: column;
  justify-content: center; 
  align-items: center;
  margin: 1px 1px 1px 1px;
 
`;

const ButtonLimpar = styled.button`
  padding-bottom: 10px;
  cursor: pointer;
  border: none;
  background-color:   #fff;
  font-weight: bold;
  font-size: 17px;
  margin: 8px;
  transition: transform 0.1s;
  &:hover {
    transform: scale(1.1);
  }
  
`;

const Button = styled.button`
  cursor: pointer;
  border: none;
  background-color:   #fff;
  font-weight: bold;
  font-size: 17px;
  margin: 8px;
  transition: transform 0.1s;
  &:hover {
    transform: scale(1.1);
  }
  
`;

const Div2 = styled.td`
  display: flex;
  flex-direction:column;
 
`;


export const Tr = styled.tr`
  text-align: center;

`;
export const Td = styled.td`
  word-break: break-word;
  padding-top: 15px;
  padding-bottom: 5px;
  text-align: ${(props) => (props.alignCenter ? "center" : "start")};
  width: ${(props) => (props.width ? props.width : "auto")};

  select {
    margin-right: 5px;
  }
 
`;

const Table = styled.table`
  width: 98%;
  padding: 20px 20px;
  margin-top: 30px;
`;

export const Th = styled.th`
  color: #4f4f4f;
  text-align: start;
  border-bottom: inset;
  padding-bottom: 5px;

`;

const Form = ({ onEdit, setOnEdit, urlApi, fetchUsuarios, pagina, modulo, acesso, fetchAcesso }) => {

 
  const [login, setLogin] = useState('');
  const [senha, setSenha] = useState('');
  const [repetirSenha, setRepetirSenha] = useState('');
  const [tipoUsuario, setTipoUsuario] = useState('');
  const [isModified, setIsModified] = useState(false);
  const ref = useRef(null);



  useEffect(() => {
    // Verifica se algum campo foi alterado
    const hasChanges = login !== '' || senha !== '' || repetirSenha !== '' || tipoUsuario !== '';
    setIsModified(hasChanges);
  }, [login, senha, repetirSenha, tipoUsuario]);


  // tokens
  const token = localStorage.getItem('token');
  const usuario = localStorage.getItem('usuario');


  //onEdit que vem do Grid para ser editado
  useEffect(() => {
    if (onEdit) {

      const user = ref.current;

      user.login.value = onEdit.login;
      user.tipo_usuario.value = onEdit.tipo_usuario;

    };

  }, [onEdit]);


  //popUp que confirma a operação
  const showPopUp = () => {
    return new Promise((resolve) => {
      Swal.fire({
        title: "Deseja confirmar a ação?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Confirmar",
        cancelButtonText: "Cancelar",
        customClass: {
          container: 'custom-swal-container',
        },
      }).then((result) => {
        resolve(result.value); // Resolve a Promise com o valor result.value
      });
    });
  };

  const saveLog = async (login, descricao) => {
    try {
      const logData = {
        login,
        descricao,
      };

      const response = await fetch(`${urlApi}/log`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
        body: JSON.stringify(logData),
      });

      if (!response.ok) {
        throw new Error('Failed to save log');
      }
    } catch (error) {
      console.error('Erro ao salvar log:', error);
    }
  };




  const handleSubmit = async (e) => {
    e.preventDefault();

    const user = ref.current;

    const loginValue = user.login.value;
    const senhaValue = user.senha.value;
    const repetirSenhaValue = user.repetirSenha.value;
    const tipoUsuarioValue = user.tipo_usuario.value;

    if (!loginValue || !tipoUsuarioValue || !senhaValue || !repetirSenhaValue) {
      return toast.warn("Preencha todos os campos!");
    }

    if (senhaValue !== repetirSenhaValue) {
      return toast.error("As senhas não coincidem!");
    }

    const userConfirmed = await showPopUp();
    if (!userConfirmed) {
      return;
    }

    try {
      if (onEdit) {
        await axios
          .put(`${urlApi}/usuarios/${onEdit.id_usuario}`, {
            login: loginValue,
            senha: senhaValue,
            tipo_usuario: tipoUsuarioValue,
          }, { headers: { Authorization: `Bearer ${token}` } })
          .then(async ({ data }) => {

            toast.success(data);

            await saveLog(usuario, `Usuário ${usuario} editou o login ${loginValue}`);
          })
          .catch(async (error) => {
            if (error.response) {
              const errorMessage = error.response.data.error || 'Erro desconhecido';
              console.log("Error:", errorMessage);
              toast.error("Erro ao editar: " + errorMessage);
              await saveLog(usuario, `Erro ao editar o login ${loginValue} pelo usuário ${usuario}: ${errorMessage}`);
            } else {
              console.error('Erro inesperado:', error);
              toast.error('Erro inesperado.');
              await saveLog(usuario, `Erro inesperado ao editar o login ${loginValue} pelo usuário ${usuario}`);
            }
          });
      } else {
        await axios
          .post(`${urlApi}/usuarios`, {
            login: loginValue,
            senha: senhaValue,
            tipo_usuario: tipoUsuarioValue,
          }, { headers: { Authorization: `Bearer ${token}` } })
          .then(async ({ data }) => {
            toast.success(data);

            await saveLog(usuario, `Usuário ${usuario} adicionou o login ${loginValue}`);
          })
          .catch(async (error) => {
            if (error.response) {
              const errorMessage = error.response.data.error || 'Erro desconhecido';
              console.log("Error:", errorMessage);
              toast.error("Erro ao adicionar: " + errorMessage);
              await saveLog(usuario, `Erro ao adicionar o login ${loginValue} pelo usuário ${usuario}: ${errorMessage}`);
            } else {
              console.error('Erro inesperado:', error);
              toast.error('Erro inesperado.');
              await saveLog(usuario, `Erro inesperado ao adicionar o login ${loginValue} pelo usuário ${usuario}`);
            }
          });
      }
    } catch (error) {
      console.error('Erro inesperado:', error);
      toast.error('Erro inesperado.');
      await saveLog(usuario, `Erro inesperado ao processar o login ${loginValue} pelo usuário ${usuario}`);
    }

  
    fetchUsuarios();
    setOnEdit(null);

    user.login.value = "";
    setSenha('');
    setRepetirSenha('')
    user.tipo_usuario.value = "";

  };





  const moduloMap = modulo.reduce((acc, mod) => {
    acc[mod.id_modulo] = mod.desc_modulo;
    return acc;
  }, {});

  const groupedPages = pagina.reduce((acc, item) => {
    if (!acc[item.id_modulo]) {
      acc[item.id_modulo] = [];
    }
    acc[item.id_modulo].push(item);
    return acc;
  }, {});

  const modulosComPaginas = Object.keys(groupedPages).map((id_modulo) => ({
    id_modulo,
    desc_modulo: moduloMap[id_modulo],
    paginas: groupedPages[id_modulo]
  }));




  const getSelectedStatus = (id_pagina) => {
    const acessoUsuario = acesso.find(acesso => (
      acesso.id_usuario === onEdit.id_usuario &&
      acesso.id_pagina === id_pagina
    ));

  
    return acessoUsuario ? acessoUsuario.status : 'N'; // Retorna 'S' se houver acesso registrado, senão retorna 'N'
  };

  const [selectedPages, setSelectedPages] = useState({});


  const handleSelectChange = (id_pagina, id_modulo, value) => {

    const data = {
      id_usuario: onEdit.id_usuario,
      id_modulo: id_modulo,
      id_pagina: id_pagina,
      status: value === 'S' ? 'S' : 'N',
    };

    const config = { headers: { Authorization: `Bearer ${token}` } };

    if (value === 'N') {
      axios.delete(`${urlApi}/acesso/${onEdit.id_usuario}/${id_modulo}/${id_pagina}`, config)
        .then((response) => {
          toast.success(response.data);
          fetchAcesso();
          console.log('Dados apagados com sucesso:', response.data);
          saveLog(usuario, `Removido acesso ID Modulo:${id_modulo}, ID pagina:${id_pagina}  do Usuário ID:${onEdit.id_usuario}`);
        })
        .catch((error) => {
          console.error('Erro ao apagar dados:', error);
        });
      
    } else if (value === 'S') {
      axios.post(`${urlApi}/acesso`, data, config)
        .then((response) => {
          fetchAcesso();
          toast.success(response.data);
          console.log('Dados salvos com sucesso:', response.data);
          saveLog(usuario, `Adicionado o acesso ID Modulo:${id_modulo}, ID pagina:${id_pagina}  do Usuário ID:${onEdit.id_usuario}`);
        })
        .catch((error) => {
          console.error('Erro ao salvar dados:', error);
        });
      
    }

    setSelectedPages((prevSelectedPages) => ({
      ...prevSelectedPages,
      [id_pagina]: value, // Atualiza o estado local com o novo valor selecionado
    }));

    fetchAcesso();
  };



  


  return (
    <>
      <FormContainer ref={ref} onSubmit={handleSubmit}>
        <Div>
          <Div2>
            <InputArea>
              <Label>Login:</Label>
              <Input name="login" type="text" placeholder="Digite o login" onChange={(e) => setLogin(e.target.value)}/>
            </InputArea>

          </Div2>

          <Div2>
            <InputArea>
              <Label>Senha:</Label>
              <Input name="senha" type="password" value={senha} onChange={(e) => setSenha(e.target.value)} placeholder="Digite sua senha" />
            </InputArea>

          </Div2>

          <Div2>
            <InputArea>
              <Label>Repetir a senha:</Label>
              <Input name="repetirSenha" type="password" value={repetirSenha} onChange={(e) => setRepetirSenha(e.target.value)} placeholder="Digite sua senha novamente"
              />
            </InputArea>

          </Div2>

          <Div2>
            <InputArea>
              <Label>Selecione:</Label>
              <select name="tipo_usuario" onChange={(e) => setTipoUsuario(e.target.value)}>
                <option value="" disabled selected>Tipo de usuário</option>
                <option value="ADMIN">Administrador</option>
                <option value="USER">Usuário</option>
              </select>
            </InputArea>
          </Div2>

          <Div2>
            <ButtonContainer>
              <ButtonLimpar title="Novo" type="button" onClick={() => window.location.reload()}>
                <ImLoop2 color={"gold"} /> Atualizar
              </ButtonLimpar>
              <Button title="Salvar" type="submit" disabled={!isModified}>
                {isModified ? <><ImCheckmark color={"green"} /> Salvar </> : <><ImCheckmark color={"gray"} /> Salvar </>}
              </Button>
            </ButtonContainer>
          </Div2>

        </Div>
        {onEdit && (
          <Table>
            <thead >
              <Tr>
                {modulosComPaginas.map((mod) => (
                  <Th key={mod.id_modulo}>{mod.desc_modulo}</Th>
                ))}
              </Tr>
            </thead>
            <tbody>
              <Tr>
                {modulosComPaginas.map((mod) => (
                  <Td key={mod.id_modulo}>
                    {mod.paginas.map((pagina) => (
                      <div key={pagina.id_pagina}>
                        <select
                          value={getSelectedStatus(pagina.id_pagina)}
                          onChange={(e) => handleSelectChange(pagina.id_pagina, mod.id_modulo, e.target.value)}
                        >

                          <option value="S">Sim</option>
                          <option value="N">Não</option>
                        </select>
                        {pagina.desc_pagina}
                      </div>
                    ))}
                  </Td>
                ))}
              </Tr>
            </tbody>
          </Table>
        )}


      </FormContainer>
    </>
  );
};

export default Form;


