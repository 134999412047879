import { useState, useEffect } from 'react';
import axios from 'axios';

//const url = "http://192.168.100.103:8840/api";
const url = "/api";
const token = localStorage.getItem('token');

export const usePaginaHook = () => {
    const [pagina, setPagina] = useState([]);

    const fetchPagina = async () => {
        try {
            const res = await axios.get(`${url}/pagina`, { headers: { Authorization: `Bearer ${token}` } });
            setPagina(res.data);
        } catch (error) {
            console.error('Erro ao buscar dados de pagina:', error);
        }
    };

    useEffect(() => {
        fetchPagina();
    }, []);

    return { pagina, fetchPagina };
};
