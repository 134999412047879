import React, { useState, useEffect } from 'react';

import axios from "axios";
import styled from "styled-components";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";





const FormContainer = styled.form`
  width: 300px;

  background-color: rgba(255, 255, 255, 0.8);
  justify-content: center; 
  display: flex; 
  padding: 5px 10px;
  box-shadow: 0px 0px 2px #ccc;
  border-radius: 1px;
  align-items: center;
  gap: 5px;
  flex-wrap: wrap;
  


`;


const Container = styled.div`
  width: 300px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: flex-start; 
  text-align: start;
  gap: 10px;
  padding: 0px 10px; 
  height: auto;


`;


const Div2 = styled.div`
  padding-top: 30px;
`;

const Div = styled.div`
  padding-top: 20px;
`;



const Input = styled.input`
  width: 250px;
  border: 1px solid #bbb;
  border-radius: 1px;
  height: 37px;
  padding: 0px 10px;

`;



const Label = styled.label`

  padding-top: 25px;
  font-weight: bold;
  font-size: 17px;
  text-align: left
  color: #808080;
  p{
    color: #4f4f4f;
  }
  

`;


const ContainerButton = styled.div`
  display: flex;
  justify-content: center; 


`;


const Button = styled.button`
  padding: 10px;
  margin: 20px;
  cursor: pointer;
  border-radius: 3px;
  border: none;
  background-color: #7A7A7A;
  color: white;
  width: 120px;
  height: 40px;
  font-weight: bold;

  transition: transform 0.3s;
  &:hover {
    transform: scale(1.1);
    background-color: #000000;
  }

`;





function Login({ urlApi }) {


  const usuario = localStorage.getItem('usuario');
  const id = localStorage.getItem('id');

  const [login, setLogin] = useState(usuario);
  const [senha, setSenha] = useState('');
  const [novaSenha, setNovaSenha] = useState('');
  const [novaSenhaNovamente, setNovaSenhaNovamente] = useState('');
  const [isButtonVisible, setIsButtonVisible] = useState(false);


  // Função de login

  const handleLogin = async () => {

    if (novaSenha !== novaSenhaNovamente) {
      return toast.error("As novas senhas não coincidem!");
    }

    try {

      const response = await fetch(`${urlApi}/trocar-senha/`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          login,
          senha,
        }),
      });

      const data = await response.json();

      if (response.ok) {
        await axios
          .put(`${urlApi}/trocar-senha/${id}`, {

            senha: novaSenha,
          })
          .then(async ({ data }) => {
            toast.success(data);
            await saveLog(usuario, `Usuário ${usuario} editou a senha`);
          })
          .catch(async (error) => {
            if (error.response) {
              const errorMessage = error.response.data.error || 'Erro desconhecido';
              console.log("Error:", errorMessage);
              toast.error("Erro ao editar: " + errorMessage);
              await saveLog(usuario, `Erro ao editar a senha: ${errorMessage}`);
            } else {
              console.error('Erro inesperado:', error);
              toast.error('Erro inesperado.');
              await saveLog(usuario, `Erro inesperado ao editar a senha`);
            }
          });

        toast.success(data.mensagem);

        // Redefinir o formulário
        const form = document.querySelector('form');


        // Salvar log de sucesso no banco de dados
        await saveLog(login, 'Senha trocada com seucesso!');

        setTimeout(function () {
          window.location.href = "/login";
        }, 2000);

      } else {
        toast.error(data.error || 'Erro desconhecido no login.');

        // Salvar log de falha no banco de dados
        await saveLog(login, `Falha no logintrocar a senha: ${data.error || 'Erro desconhecido'}`);
      }
    } catch (error) {
      alert('Erro de rede ou erro desconhecido.');

      // Salvar log de falha de rede no banco de dados
      await saveLog(login, 'Erro de rede ou erro desconhecido');
    }
  };

  // Passa o logs para serem salvos no banco
  const saveLog = async (login, descricao) => {
    try {
      const logData = {
        login,
        descricao,
      };

      const response = await fetch(`${urlApi}/log`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',

        },
        body: JSON.stringify(logData),
      });

      if (!response.ok) {
        throw new Error('Failed to save log');
      }
    } catch (error) {
      console.error('Erro ao salvar log:', error);
    }
  };




  useEffect(() => {
    // Verifica se todos os campos estão preenchidos
    if (senha && novaSenha && novaSenhaNovamente) {
      setIsButtonVisible(true);
    } else {
      setIsButtonVisible(false);
    }
  }, [senha, novaSenha, novaSenhaNovamente]);


  return (
    <FormContainer >
      <Container>
        <Div2>
          <Div>
            <Label>Usuário:</Label>
            <Input
              type="text"
              placeholder="Digite aqui..."
              value={login}
              onChange={(e) => setLogin(e.target.value)}
              disabled
            />

          </Div>
          <Div>
            <Label>Senha:</Label>
            <Input
              type="password"
              placeholder="Digite aqui..."
              value={senha}
              onChange={(e) => setSenha(e.target.value)}
            />
          </Div>

        </Div2>

        <Div2>
          <Div>
            <Label>Nova Senha:</Label>

            <Input
              type="password"
              placeholder="Digite aqui..."
              onChange={(e) => setNovaSenha(e.target.value)}
            />
          </Div>
          <Div>
            <Label>Nova Senha:</Label>
            <Input
              type="password"
              placeholder="Digite aqui..."
              onChange={(e) => setNovaSenhaNovamente(e.target.value)}
            />
          </Div>

        </Div2>


        <ContainerButton>
          {isButtonVisible && (
            <Button type="button" onClick={handleLogin}>
              Trocar a senha
            </Button>
          )}
        </ContainerButton>
      </Container>
    </FormContainer>
  );
}

export default Login;

