import React, { useState, useEffect } from "react";
import axios from "axios"
import { useModuloHook } from '../../hook/getModulo.js';
import { usePaginaHook } from '../../hook/getPagina.js';
import { useAcessoHook } from '../../hook/getAcesso.js';
import { useUsuariosHook } from '../../hook/getUsuarios.js';

import Form from "./components/Form.js";
import Grid from "./components/Grid.js";
import NovoModulo from "./components/NovoModulo.js";

import { FaBars, FaWindowClose, FaUserPlus } from "react-icons/fa";
import GlobalStyle from "../../styles/global.js";
import logo from "../../styles/imagem/Logomarca Cooperval-02.png";
import { FaHome } from "react-icons/fa";
import { ImExit } from "react-icons/im";
import { IoClose } from "react-icons/io5";
import styled from "styled-components";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";


const DivLayout = styled.div`
  width: 100vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow: auto;
`;

const Container = styled.div`
  width: 100vw;
  display: flex;
  flex-direction: column;
  justify-content: center; 
  align-items: center;
  box-sizing: border-box;
`;

const Div = styled.div`
background-color:  #fff;
`;

const Title = styled.nav`
  width: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
  height: 5.5rem;
  background-color: #076633;
`;

const Title2 = styled.h2`
  width: 100%;
  color: #fff;
  font-size: 28px;
  display: flex;
  justify-content: space-around; 
  align-items: center;

  img{
    height: 50px;
    padding-top: 1.5%;
  }  
`;

const TitleNavBar = styled.nav`
  width: 100%;
  height: 3.5rem;
  background-color: #A6A29F;
  display: flex;
  justify-content: space-around;
  align-items: center;

  &.sidebar.closed {
  display: none; 
  }

  &.sidebar.open {
  display: block; 
  }

`;

const Title3 = styled.h2`
  width: 100%;
  color: #fff;
  font-size: 28px;
  display: flex;
  justify-content: space-around; 
  align-items: center;
   
`;

const Filtro = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;


`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: start; 
`;

const ModalContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
`;

const ModalContent = styled.div`
  background-color: white;
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5);
  max-height: 80vh; 
  overflow-y: auto; 
`;

const ButtonClose = styled.button`
  position: fixed;
  cursor: pointer;
  border: none;
  background-color: #fff;
  transition: transform 0.1s;
  &:hover {
    transform: scale(1.5);
  }
`;

const ButtonSide = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 12px;
  cursor: pointer;
  border: none;
  background-color: #A6A29F;
  color: #fff;
  font-size: 20px;

`;

const ButtonSide1 = styled.button`
  
  cursor: pointer;
  border: none;
  background-color: #076633;
  color: #fff;
  font-size: 18px;

  &.toggle-btn {
    position: absolute;
    top: 2;
    left: 0;
    padding-left: 1vw;
  }

  &.button-exit{
    position: absolute;
    top: 2;
    right: 0;
    padding-right: 1vw;
  }

`;




function Modulos() {
  const id = localStorage.getItem('id');
  const token = localStorage.getItem('token');
  
  
  //const urlFront = "http://192.168.100.103:3040/";
  //const urlApi = "http://192.168.100.103:8840/api";
  const urlFront = "/";
  const urlApi = "/api";

  const { usuarios } = useUsuariosHook();
  const { modulo, fetchModulo } = useModuloHook();
  const { pagina, fetchPagina } = usePaginaHook();
  const { fetchAcesso } = useAcessoHook();

  const [onEdit, setOnEdit] = useState("");

  const [isModalOpenNovoModulo, setIsModalOpenNovoModulo] = useState(false);
  const modalNovoModulo = () => {
    setIsModalOpenNovoModulo(!isModalOpenNovoModulo);
  };



  //Links
  const handleUsuarios = () => {
    const url = `${urlFront}cadastro-usuarios/`;
    window.location.href = url;
  };
  const handleDashboard = () => {
    const url = urlFront;
    window.location.href = url;
  };
  const handleExit = () => {
    localStorage.removeItem('token');
    localStorage.removeItem('id');
    localStorage.removeItem('usuario');

    const url = `login`;
    window.location.href = url;
  };

  //Verifica o token do usuário
  const [tToken, setToken] = useState("");
  const getToken = async () => {
    try {
      const res = await axios.get(`${urlApi}/login/token`, { headers: { Authorization: `Bearer ${token}` } });
      setToken(res.data);
    } catch (error) {
      if (error.response && (error.response.status === 400 || error.response.status === 403 || error.response.status === 401)) {
        window.location.href = `${urlFront}login`;
      } else {
        console.error("Erro ao obter token:", error);
      }
    }
  };

  useEffect(() => {
    getToken();
  });


  const [isOpenSide, setIsOpenSide] = useState(false);
  const toggleSidebar = () => {
    setIsOpenSide(!isOpenSide);

  };

  useEffect(() => {
    const usuarioFiltrado = usuarios.find(usuario => usuario.id_usuario == id);
    if (usuarioFiltrado) {
      if (usuarioFiltrado.tipo_usuario !== "ADMIN"){
        const url = `/login`;
        window.location.href = url;
      }
    }
    
  }, [id, usuarios]);


  return (


    <div >
      <DivLayout>
        <Title>
          <div>
            <ButtonSide1 className="toggle-btn desativaBotao" onClick={toggleSidebar}>
              {isOpenSide ? <FaWindowClose className="gap" /> : <FaBars />}
            </ButtonSide1>
          </div>

          <Title2>
            <div>
              <h2>Cadastro de Links</h2>
            </div>



            <img src={logo} style={{ width: '180px' }} alt="Logo" />
          </Title2>
          <div>
            <ButtonSide1 className="button-exit" onClick={handleExit} title={"Sair da Central da TI"}>
              <ImExit />
            </ButtonSide1>
          </div>
        </Title>

        <TitleNavBar className={`sidebar ${isOpenSide ? 'open' : 'closed'}`}>
          <Title3>

            <ButtonSide onClick={handleDashboard}  >
              <><FaHome /> Dashboard</>
            </ButtonSide>


            <ButtonSide onClick={handleUsuarios} title={"Cadastro de usuários"}>
              <><FaUserPlus /> Usuários</>
            </ButtonSide>

          </Title3>
        </TitleNavBar>
      </DivLayout>
      <Div>
        {isModalOpenNovoModulo && (
          <ModalContainer>
            <ModalContent>
              <ButtonContainer>
                <ButtonClose onClick={() => {
                  modalNovoModulo();

                }}>
                  {isModalOpenNovoModulo ? <IoClose color={"red"} size={25} /> : <IoClose color={"red"} size={25} />}
                </ButtonClose>
              </ButtonContainer>
              <NovoModulo modalNovoModulo={modalNovoModulo} urlApi={urlApi} modulo={modulo} fetchModulo={fetchModulo} pagina={pagina} />
            </ModalContent>
          </ModalContainer>
        )}
      </Div>
      <Container>
        <Filtro>
          <Form urlApi={urlApi} fetchModulo={fetchModulo} modulo={modulo} modalNovoModulo={modalNovoModulo}  fetchPagina={fetchPagina} onEdit={onEdit} fetchAcesso={fetchAcesso}/>
          <Grid setOnEdit={setOnEdit} urlApi={urlApi} pagina={pagina} fetchPagina={fetchPagina} modulo={modulo} />
        </Filtro>

      </Container>


      <ToastContainer autoClose={1500} position={toast.POSITION.BOTTOM_LEFT} style={{ width: '400px', fontSize: '22px' }} />
      <GlobalStyle />
    </div>
  );
}

export default Modulos;
