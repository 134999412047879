import { useState, useEffect } from 'react';
import axios from 'axios';

//const url = "http://192.168.100.103:8840/api";
const url = "/api";
const token = localStorage.getItem('token');

export const useAcessoHook = () => {
    const [acesso, setAcesso] = useState([]);

    const fetchAcesso = async () => {
        try {
            const res = await axios.get(`${url}/acesso`, { headers: { Authorization: `Bearer ${token}` } });
            setAcesso(res.data);
        } catch (error) {
            console.error('Erro ao buscar dados de acesso:', error);
        }
    };

    useEffect(() => {
        fetchAcesso();
    }, []);

    return { acesso, fetchAcesso };
};
