import { useState, useEffect } from 'react';
import axios from 'axios';

//const url = "http://192.168.100.103:8840/api";
const url = "/api";
const token = localStorage.getItem('token');


export const useUsuariosHook = () => {
    const [usuarios, setUsuarios] = useState([]);

    const fetchUsuarios = async () => {
        try {
            const res = await axios.get(`${url}/usuarios`, { headers: { Authorization: `Bearer ${token}` } });
            setUsuarios(res.data);
        } catch (error) {
            console.error('Erro ao buscar dados de usuarios:', error);
        }
    };

    useEffect(() => {
        fetchUsuarios();
    }, []);

    return { usuarios, fetchUsuarios };
};
