import axios from "axios";
import React, { useRef, useEffect, useState } from "react";
import styled from "styled-components";
import { toast } from "react-toastify";
import { ImCheckmark } from "react-icons/im";
import { ImPencil2, ImBin } from "react-icons/im";
import Swal from "sweetalert2";



const FormContainer = styled.form`
  width: auto;
  background-color: #fff;
  display: flex; 
  align-items: flex-end;
  gap: 5px;
  padding: 10px;
  margin-top: 30px;
  box-shadow: 0px 0px 2px #ccc;

`;

const InputArea = styled.div`
  width: auto;
  display: flex;
  flex-direction: column;
`;

const Input = styled.input`
  width: 905px;
  padding: 0 10px;
  border: 1px solid #bbb;
  border-radius: 1px;
  height: 37px;

  
`;

const Label = styled.label`
  color: #4f4f4f;
  font-weight: bold;
  font-size: 17px;

 
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end; 
  padding-left: 1px;

  
`;

const Button = styled.button`
  padding: 20px 10px 10px 10px;
  cursor: pointer;
  border-radius: 2px;
  border: none;
  background-color:  #fff;
  height: 42px;
  font-size: 16px;
  font-weight: bold;
  margin: 5px;
  transition: transform 0.1s;
  &:hover {
    transform: scale(1.1);

  }
`;


const Table = styled.table`
  width: 100%;
  background-color: #fff;
  padding: 20px 20px;
  box-shadow: 0px 0px 2px #ccc;
  border-radius: 0.5px;


`;

export const TdButton = styled.td`
  font-size: 14px;
  font-weight: bold;
  word-break: break-word;
  padding-top: 15px;
  padding-bottom: 5px;
  cursor: pointer;
`;

export const Thead = styled.thead``;

export const Tbody = styled.tbody`
  padding-bottom: 25px; 
`;

export const Tr = styled.tr`
  text-align: center;

  @media (max-width: 640px) {
    align-items: center; 
    text-align: center;
  }
  @media (max-width: 420px) {
    align-items: center; 
    text-align: center;
  }
`;

export const Th = styled.th`
  color: #4f4f4f;
  text-align: start;
  border-bottom: inset;
  padding-bottom: 5px;

  
`;

export const Td = styled.td`
  word-break: break-word;
  padding-top: 15px;
  padding-bottom: 5px;
  text-align: ${(props) => (props.alignCenter ? "center" : "start")};
  width: ${(props) => (props.width ? props.width : "auto")};
 
 
`;

export const Td1 = styled.td`
  padding-top: 15px;
  text-align: center;

  
`;

export const Td2 = styled.td`
  word-break: break-word;
  padding-top: 15px;
  padding-bottom: 5px;
  text-align: ${(props) => (props.alignCenter ? "center" : "start")};
  width: ${(props) => (props.width ? props.width : "auto")};
 
  
`;

export const Td3 = styled.td`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-around;

  span{
    margin: 0px 5px;
  }

`;






const Form = ({ urlApi, modulo, fetchModulo, pagina }) => {
  const ref = useRef();





  const token = localStorage.getItem('token');
  const usuario = localStorage.getItem('usuario');





  //Salvar
  const handleSubmit = async (e) => {
    e.preventDefault();

    const user = ref.current;
    const desc_moduloValue = user.desc_modulo.value;

    if (!desc_moduloValue) {
      return toast.warn("Preencha todos os campos!");
    }

    try {
      if (onEdit) {
        const response = await axios.put(`${urlApi}/modulo/${onEdit.id_modulo}`, {
          desc_modulo: desc_moduloValue,
        }, { headers: { Authorization: `Bearer ${token}` } });

        toast.success(response.data);
        fetchModulo();
        // Log de sucesso
        await axios.post(`${urlApi}/log`, {
          login: usuario,
          descricao: "Modulo atualizado com sucesso: " + desc_moduloValue,
        }, { headers: { Authorization: `Bearer ${token}` } });

      } else {
        const response = await axios.post(`${urlApi}/modulo/`, {
          desc_modulo: desc_moduloValue,
        }, { headers: { Authorization: `Bearer ${token}` } });

        toast.success(response.data);
        fetchModulo();
        // Log de sucesso
        await axios.post(`${urlApi}/log`, {
          login: usuario,
          descricao: "Modulo cadastrado com sucesso: " + desc_moduloValue,
        }, { headers: { Authorization: `Bearer ${token}` } });

      }
      

    } catch (error) {
      let errorMessage = "Erro desconhecido";
      let details = "";
      let dbError = "";

      if (error.response) {
        errorMessage = error.response.data.error || errorMessage;
        details = error.response.data.details || details;
        dbError = error.response.data.dbError || dbError;
        console.log("Error:", errorMessage);
        console.log("Details:", details);
        console.log("Database Error:", dbError);
        toast.error("Erro ao adicionar: " + errorMessage);
      } else {
        console.error('Erro na solicitação:', error.message);
        toast.error('Erro na solicitação: ' + error.message);
      }

      // Log de erro
      await axios.post(`${urlApi}/log`, {
        login: usuario,
        descricao: "Erro ao cadastrar modulo: " + errorMessage,
      }, { headers: { Authorization: `Bearer ${token}` } });
    }

    user.desc_modulo.value = "";
    setOnEdit(null);
  };









  //onEdit para editar os modulo
  const [onEdit, setOnEdit] = useState(null);
  const handleEdit = (item) => {
    setOnEdit(item);
  };







  // useEffect do onEdit para editar os modulo
  useEffect(() => {
    if (onEdit) {

      const user = ref.current;
      user.desc_modulo.value = onEdit.desc_modulo;

    };
  }, [onEdit]);






  //PopUp de confirmação para deletar
  const showPopUp = (detalhes) => {
    return new Promise((resolve) => {
      Swal.fire({
        title: `Essa ação é irreversível, tem certeza que gostaria de deletar esse modulo ${detalhes}?`,
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Confirmar",
        cancelButtonText: "Cancelar",
        customClass: {
          container: 'custom-swal-container',
        },
      }).then((result) => {
        resolve(result.value); // Resolve a Promise com o valor result.value
      });
    });
  };







  // Deleta os usuários
  const handleDelete = async (item) => {
    const detalhes = `${item.desc_modulo}`
    const userConfirmed = await showPopUp(detalhes);

    const moduloExistsInPagina = pagina.some(p => p.id_modulo === item.id_modulo);

    if (moduloExistsInPagina) {
      // Se o id_modulo estiver presente, não permitir a deleção e exibir uma mensagem de erro
      
      return toast.warn("Não é possível deletar o item, pois ele está associado a uma pagina existente.");
    }


    if (!userConfirmed) {
      return;
    }

    try {
      // Tenta realizar a requisição para deletar o usuário
      const { data } = await axios.delete(`${urlApi}/modulo/${item.id_modulo}`, {
        headers: { Authorization: `Bearer ${token}` },
      });

      // Atualiza os estados locais após a exclusão bem-sucedida
      const newArray = modulo.filter((mod) => mod.id_modulo !== item.id_modulo);


      toast.success("Módulo deletado com sucesso.");
      fetchModulo();
      // Cria log de exclusão bem-sucedida
      await axios.post(`${urlApi}/log`, {
        login: usuario,
        descricao: `id${item.id_modulo} / desc: ${item.desc_modulo} deletado com sucesso.`,
      }, {
        headers: { Authorization: `Bearer ${token}` },
      });
    } catch (error) {
      // Tratamento de erros
      console.error('Erro ao deletar o usuário:', error);

      let errorMessage = 'Erro desconhecido ao deletar.';
      if (error.response) {
        errorMessage = error.response.data || errorMessage;
        console.log('Erro na resposta:', error.response);
      } else if (error.request) {
        console.log('Erro na requisição:', error.request);
      } else {
        console.log('Erro na configuração da requisição:', error.message);
      }

      toast.error(errorMessage);

      // Cria log de erro na exclusão
      await axios.post(`${urlApi}/log`, {
        login: usuario,
        descricao: `Erro ao deletar o usuário com id ${item.desc_modulo} ${errorMessage}`,
      }, {
        headers: { Authorization: `Bearer ${token}` },
      });
    }

    setOnEdit(null);
  };









  return (
    <div>
      <FormContainer ref={ref} onSubmit={handleSubmit}>
        <InputArea>
          <Label>Crie um novo modulo:</Label>
          <Input name="desc_modulo" type="text" placeholder="Digite aqui..." />
        </InputArea>


        <ButtonContainer>
          <Button title="Salvar" type="submit">
            <ImCheckmark color={"green"} /> Salvar
          </Button>
        </ButtonContainer>
      </FormContainer>


      <Table >
        <Thead>

          <Tr>
            <Th>ID:</Th>
            <Th>Descrição</Th>
            <Th></Th>
            <Th></Th>
            <Th></Th>
          </Tr>
        </Thead>
        <Tbody>
          {modulo.map((item, i) => (
            <Tr key={i}>
              <Td width="auto">{item.id_modulo}</Td>
              <Td width="auto">{item.desc_modulo}</Td>

              <TdButton width="3%" >
                <ImPencil2 size={18} color={'gold'} onClick={() => handleEdit(item)} />
              </TdButton>
              <TdButton width="3%" >
                <ImBin size={18} color={'Red'} onClick={() => handleDelete(item)} />
              </TdButton>
            </Tr>
          ))}
        </Tbody>


      </Table>
      <style jsx global>{`
      .swal2-container.custom-swal-container {
        z-index: 9999; 
      }
    `}</style>
    </div>

  );
};

export default Form;


