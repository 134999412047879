import React, { useState, useEffect } from "react";
import axios from "axios";
import styled from "styled-components";
import { toast } from "react-toastify";
import { ImPencil2, ImBin } from "react-icons/im";
import { ImArrowLeft, ImArrowRight } from "react-icons/im";
import Swal from "sweetalert2";

const Table = styled.table`
  width: 100%;
  background-color: #fff;
  padding: 20px 20px;
  box-shadow: 0px 0px 2px #ccc;
  border-radius: 0.5px;


`;

export const TdButton = styled.td`
  font-size: 14px;
  font-weight: bold;
  word-break: break-word;
  padding-top: 15px;
  padding-bottom: 5px;
  cursor: pointer;
`;

export const Thead = styled.thead``;

export const Tbody = styled.tbody`
  padding-bottom: 25px; 
`;

export const Tr = styled.tr`
  text-align: center;


`;

export const Th = styled.th`
  color: #4f4f4f;
  text-align: start;
  border-bottom: inset;
  padding-bottom: 5px;


`;

export const Td = styled.td`
  word-break: break-word;
  padding-top: 15px;
  padding-bottom: 5px;
  text-align: ${(props) => (props.alignCenter ? "center" : "start")};
  width: ${(props) => (props.width ? props.width : "auto")};
 

`;

export const Td1 = styled.td`
  padding-top: 15px;
  text-align: center;


`;

export const Td2 = styled.td`
  word-break: break-word;
  padding-top: 15px;
  padding-bottom: 5px;
  text-align: ${(props) => (props.alignCenter ? "center" : "start")};
  width: ${(props) => (props.width ? props.width : "auto")};
 
`;

export const Td3 = styled.td`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-around;

  span{
    margin: 0px 5px;
  }

`;

const Input = styled.input`
  width: 345px;
  padding: 0 10px;
  border: 1px solid #bbb;
  border-radius: 1px;
  height: 37px;


`;

const Button = styled.button`
  
  cursor: pointer;
  border: none;
  background-color: #e8eaeb;
  color: black;

  transition: transform 0.1s;
  &:hover {
    transform: scale(1.3);
  }
`;

const Div = styled.div`
display: flex;
align-items: center;
  background-color: #e8eaeb;
  
  span{
    padding: 0px  10px;
  }
`;

const Grid = ({ setOnEdit, urlApi, usuarios, fetchUsuarios }) => {

  //Token da api
  const token = localStorage.getItem('token');
  const usuario = localStorage.getItem('usuario');


  const [searchTerm, setSearchTerm] = useState('');
  const [filteredUsers, setFilteredUsers] = useState(usuarios);
  const [isLoading, setIsLoading] = useState(true);


  //PopUp de confirmação
  const showPopUp = () => {
    return new Promise((resolve) => {
      Swal.fire({
        title: "Deseja deletar o usuário?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Confirmar",
        cancelButtonText: "Cancelar",
        customClass: {
          container: 'custom-swal-container',
        },
      }).then((result) => {
        resolve(result.value); // Resolve a Promise com o valor result.value
      });
    });
  };


  const handleEdit = (item) => {
    setOnEdit(item);
  };








  // Deleta os usuários
  const handleDelete = async (item) => {
    const userConfirmed = await showPopUp();

    if (!userConfirmed) {
      return;
    }

    try {
      // Tenta realizar a requisição para deletar o usuário
      const { data } = await axios.delete(`${urlApi}/usuarios/${item.id_usuario}`, {
        headers: { Authorization: `Bearer ${token}` },
      });

      // Atualiza os estados locais após a exclusão bem-sucedida
      const newArray = usuarios.filter((listaUsuario) => listaUsuario.id_usuario !== item.id_usuario);
      setFilteredUsers(newArray);

      toast.success("Usuário deletado com sucesso.");

      // Cria log de exclusão bem-sucedida
      await axios.post(`${urlApi}/log`, {
        login: usuario,
        descricao: `Usuário com id ${item.id_usuario} - ${item.login} deletado com sucesso.`,
      }, {
        headers: { Authorization: `Bearer ${token}` },
      });
    } catch (error) {
      // Tratamento de erros
      console.error('Erro ao deletar o usuário:', error);

      let errorMessage = 'Erro desconhecido ao deletar.';
      if (error.response) {
        errorMessage = error.response.data || errorMessage;
        console.log('Erro na resposta:', error.response);
      } else if (error.request) {
        console.log('Erro na requisição:', error.request);
      } else {
        console.log('Erro na configuração da requisição:', error.message);
      }

      toast.error(errorMessage);

      // Cria log de erro na exclusão
      await axios.post(`${urlApi}/log`, {
        login: usuario,
        descricao: `Erro ao deletar o usuário com id ${item.id_usuario} - ${item.login} ${errorMessage}`,
      }, {
        headers: { Authorization: `Bearer ${token}` },
      });
    }

    //setOnEdit(null);
    fetchUsuarios();
  };




  //Input de pesquisa
  const handleSearch = async (term) => {
    setIsLoading(true);

    try {
      const filtered = usuarios.filter((user) => {
        const login = user.login ? user.login.toLowerCase() : ''; // Verifica se user.logon é null ou undefined

        return (
          login.includes(term.toLowerCase())
        );
      });
      setFilteredUsers(filtered);
    } catch (error) {
      console.error("Erro ao pesquisar:", error);
    }

    setIsLoading(false);
    //setSearchTerm('');
  };

  useEffect(() => {

    handleSearch(searchTerm);
  }, [searchTerm, usuarios]);





  //Paginação
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10; // Defina a quantidade de itens por página conforme necessário
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = filteredUsers.slice(indexOfFirstItem, indexOfLastItem);


  const totalPages = Math.ceil(filteredUsers.length / itemsPerPage);
  // Função para ir para a próxima página
  const handleNextPage = () => {
    setCurrentPage((prevPage) => Math.min(prevPage + 1, totalPages));
  };
  // Função para ir para a página anterior
  const handlePrevPage = () => {
    setCurrentPage((prevPage) => Math.max(prevPage - 1, 1));
  };


  return (

    <>
      <Td3 colSpan="7">
        <Input
          type="text"
          placeholder="Pesquisar... Ex: login"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />

        <Div>
          <Button onClick={handlePrevPage} disabled={currentPage === 1}>
            <ImArrowLeft size={20} />
          </Button>
          <span>Página <strong>{currentPage}</strong> de <strong>{totalPages}</strong></span>
          <Button onClick={handleNextPage} disabled={currentPage === totalPages}>
            <ImArrowRight size={20} />
          </Button>
        </Div>
      </Td3>
      <Table >
        <Thead>

          <Tr>
            <Th></Th>
            <Th onlyWeb>Login:</Th>
            <Th onlyWeb></Th>
            <Th onlyWeb></Th>
            <Th onlyWeb></Th>
          </Tr>
        </Thead>
        <Tbody>
          {currentItems.map((item, i) => (
            <Tr key={i}>
              <Td width="auto">{item.id_usuario}</Td>
              <Td width="auto">{item.login}</Td>
              <Td width="auto">{item.tipo_usuario}</Td>
              <TdButton width="3%" onClick={() => handleEdit(item)}>
                <ImPencil2 size={18} color={'gold'} />
              </TdButton>
              <TdButton  width="3%" onClick={() => handleDelete(item)}>
                <ImBin size={18} color={'Red'} />
              </TdButton>
            </Tr>
          ))}
        </Tbody>


      </Table>

    </>
  );
};


export default Grid;
