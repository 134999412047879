import React from 'react';
import './Sidebar.css';
import placeholderImage from './fundo2.png';

const GridBi = ({ currentUrl }) => {
  return (
    <>
      {currentUrl ? (
        <div>
          <iframe src={currentUrl} title="iframe" className='iframe'></iframe>
        </div>

      ) : (
        <img src={placeholderImage} alt="Placeholder" className="placeholder-image" />
      )}
    </>
  );
};

export default GridBi;
